import consumer from "./consumer"

consumer.subscriptions.create("StatusImportPriceChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    //console.log("Connected to the StatusImportPriceChannel!");

  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    //console.log("Disconnected:")
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //console.log(data)
    $('#count_dj').text(data);
    if (data > 0) {
      $('#color_dj').addClass("red")
      $('#color_dj').removeClass("green")
    }else {
      $('#color_dj').addClass("green")
      $('#color_dj').removeClass("red")
    }


  }
});
